<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Connected Account</b-card-title>
    </b-card-header>
    <b-card-body>
      <hr />
      <b-card-text class="mb-2">
        Hubungkan akun media sosial untuk mendapatkan limit data lebih banyak
      </b-card-text>
      <!-- MEDIA ICON -->
      <div
        v-for="data in status"
        :key="data.id"
        class="d-flex align-items-center mb-1"
      >
        <!-- FACEBOOK -->
        <i
          v-if="data.media == 'Facebook'"
          class="bi-facebook facebook-color fa-2x"
        ></i>

        <!-- TWITTER -->
        <i
          v-if="data.media == 'Twitter'"
          class="bi-twitter twitter-color fa-2x"
        ></i>
        <div>
          <!-- MEDIA NAME -->
          <strong class="ml-2">{{ data.media }}</strong>

          <!-- MEDIA CONNECTION STATUS -->
          <b-card-text v-if="data.connected" class="ml-2">
            <a :href="data.link">{{ data.link }}</a>
          </b-card-text>
          <b-card-text v-else class="ml-2">Not Connected</b-card-text>
        </div>
        <div class="ml-auto">
          <!-- DISCONNET BUTTON -->
          <b-button
            v-if="data.connected"
            variant="danger"
            @click="disconnect(data.id)"
          >
            Putuskan
          </b-button>

          <!-- CONNECT BUTTON -->
          <b-button v-else variant="primary" @click="connect(data.id)">
            Hubungkan
          </b-button>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardText,
  BButton,
} from "bootstrap-vue";

export default {
  name: "cardConnectedAccount",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BButton,
  },

  data() {
    return {
      status: [
        {
          id: 1,
          media: "Facebook",
          link: "www.facebook.com",
          connected: false,
        },
        {
          id: 2,
          media: "Twitter",
          link: "www.twitter.com",
          connected: true,
        },
      ],
    };
  },

  methods: {
    // MEDIA TO MEDIA
    connect(id) {
      this.userStatus = this.status.find((el)=> el.id == id)
      this.userStatus.connected = true
    },
    // DISCONNECT TO MEDIA
    disconnect(id) {
      this.userStatus = this.status.find((el)=> el.id == id)
      this.userStatus.connected = false
    },
  },
};
</script>