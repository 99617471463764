<template>
  <div class="row">
    <!-- COLUMN 1 -->
    <div class="col-md-6">
      <!-- MEDIA KONVENSIONAL -->
      <cardChannelMedkon />

      <!-- CONNECTED ACCOUNT -->
      <cardConnectedAccount />
    </div>

    <!-- COLUMN 2 -->
    <div class="col-md-6">
      <!-- MEDIA SOSIAL -->
      <cardChannelMedsos />
    </div>
  </div>
</template>
<script>
import cardChannelMedkon from "@/components/setting/channel/cardChannelMedkon.vue";
import cardChannelMedsos from "@/components/setting/channel/cardChannelMedsos.vue";
import cardConnectedAccount from "@/components/setting/channel/cardConnectedAccount.vue";
export default {
  name: "Channel",
  components: {},

  components: {
    cardChannelMedkon,
    cardChannelMedsos,
    cardConnectedAccount,
  },

  data() {
    return {
      title: "Channel | Online Media Monitoring",
    };
  },

  created() {
    document.title = this.title;
  },
};
</script>