<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Media Konvensional</b-card-title>
    </b-card-header>
    <b-card-body>
      <hr />
      <b-card-text class="mb-2"
        >Daftar sumber data yang akan dimonitor</b-card-text
      >
      <div
        v-for="data in media_data"
        :key="data.id"
        class="d-flex align-items-center mb-1"
      >
        <!-- MEDIA AVATAR -->
        <b-avatar class="mr-1 bg-light-primary">
          <!-- MEDIA ONLINE -->
          <i
            v-if="data.media == 'Media Online'"
            class="bi-globe media-online-color fa-xl"
          ></i>

          <!-- MEDIA CETAK -->
          <i
            v-if="data.media == 'Media Cetak'"
            class="bi-newspaper media-cetak-color fa-xl"
          ></i>

          <!-- MEDIA ELEKTRONIK -->
          <i
            v-if="data.media == 'Media Elektronik'"
            class="bi-display media-elektronik-color fa-xl"
          ></i>
        </b-avatar>
        <div>
          <!-- MEDIA NAME -->
          <div>
            <b-card-text>
              <strong>{{ data.media }}</strong>
            </b-card-text>
          </div>

          <!-- MEDIA DESCRIPTION -->
          <b-card-text>{{ data.deskripsi }}</b-card-text>
        </div>
        <div class="ml-auto">
          <!-- SWITCH BUTTON -->
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormCheckbox,
  BAvatar,
} from "bootstrap-vue";

export default {
  name: "cardChannelMedkon",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormCheckbox,
    BAvatar,
  },

  data() {
    return {
      media_data: [
        {
          id: 1,
          media: "Media Online",
          deskripsi:
            "Portal berita online atau website yang mempublikasikan berita",
        },
        {
          id: 2,
          media: "Media Cetak",
          deskripsi: "Koran, majalah, dan  media cetak lainnya",
        },
        {
          id: 3,
          media: "Media Elektronik",
          deskripsi: "Tayangan berita atau program berita di Televisi",
        },
      ],
    };
  },
};
</script>