<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Media Sosial</b-card-title>
    </b-card-header>
    <b-card-body>
      <hr />
      <b-card-text class="mb-2"
        >Daftar sumber data yang akan dimonitor</b-card-text
      >
      <div
        v-for="data in media_data"
        :key="data.id"
        class="d-flex align-items-center mb-1"
      >
        <!-- MEDIA AVATAR -->
        <b-avatar class="mr-1 bg-light-primary">
          <!-- TWITTER -->
          <i
            v-if="data.media == 'Twitter'"
            class="bi-twitter twitter-color fa-xl"
          ></i>

          <!-- FACEBOOK -->
          <i
            v-else-if="data.media == 'Facebook'"
            class="bi-facebook facebook-color fa-xl"
          ></i>

          <!-- INSTAGRAM -->
          <i
            v-else-if="data.media == 'Instagram'"
            class="bi-instagram instagram-color fa-xl"
          ></i>

          <!-- TIKTOK -->
          <i
            v-else-if="data.media == 'Tiktok'"
            class="bi-tiktok tiktok-color fa-xl"
          ></i>

          <!-- YOUTUBE -->
          <i
            v-else-if="data.media == 'Youtube'"
            class="bi-youtube youtube-color fa-xl"
          ></i>

          <!-- LINKEDIN -->
          <i
            v-else-if="data.media == 'Linkedin'"
            class="bi-linkedin linkedin-color fa-xl"
          ></i>

          <!-- FORUM -->
          <i
            v-else-if="data.media == 'Forum'"
            class="fa-solid fa-comments forum-color fa-xl"
          ></i>

          <!-- BLOG -->
          <i
            v-else-if="data.media == 'Blog'"
            class="bi-rss-fill blog-color fa-xl"
          ></i>
        </b-avatar>
        <div>
          <!-- MEDIA NAME -->
          <div>
            <b-card-text>
              <strong>{{ data.media }}</strong>
            </b-card-text>
          </div>

          <!-- MEDIA DESCRIPTION -->
          <b-card-text>{{ data.deskripsi }}</b-card-text>
        </div>
        <div class="ml-auto">
          <!-- SWITCH BUTTON -->
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormCheckbox,
  BAvatar,
} from "bootstrap-vue";

export default {
  name: "cardChannelMedsos",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormCheckbox,
    BAvatar,
  },

  data() {
    return {
      media_data: [
        {
          id: 1,
          media: "Twitter",
          deskripsi:
            "Portal berita online atau website yang mempublikasikan berita",
        },
        {
          id: 2,
          media: "Facebook",
          deskripsi: "Koran, majalah, dan  media cetak lainnya",
        },
        {
          id: 3,
          media: "Instagram",
          deskripsi: "Tayangan berita atau program berita di Televisi",
        },
        {
          id: 4,
          media: "Tiktok",
          deskripsi: "Tayangan berita atau program berita di Televisi",
        },
        {
          id: 5,
          media: "Youtube",
          deskripsi: "Tayangan berita atau program berita di Televisi",
        },
        {
          id: 6,
          media: "Linkedin",
          deskripsi: "Tayangan berita atau program berita di Televisi",
        },
        {
          id: 7,
          media: "Forum",
          deskripsi: "Tayangan berita atau program berita di Televisi",
        },
        {
          id: 8,
          media: "Blog",
          deskripsi: "Tayangan berita atau program berita di Televisi",
        },
      ],
    };
  },
};
</script>